body {
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
}

.Privacy {
  padding: 0 16px;
}

.App {
  padding: 0 16px;
  width: 620px;
  max-width: 100vw;
  box-sizing: border-box;
}

.logo {
  max-width: 420px;
}

.screenshot {
/*  max-width: 480px;*/
}

.logo {
  width: 100%;
}

.heading {
  text-align: center;
}

.subheading {
  text-align: center;
}

.screenshot {
  width: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, .15);
}

.screenshot-wrapper {
  margin: 32px 0;
  text-align: center;
}

.mailchimp-wrapper {
  margin: 16px 0;
}

#mc_embed_signup{
  border: 1px solid #ccc; 
  background:#fff; 
  clear:left; 
  font:14px Helvetica,Arial,sans-serif; 
}

.privacy-wrapper {
  text-align: center;
  margin-top: 28px;
  margin-bottom: 28px;
}

.privacy-wrapper a {
  text-decoration: none;
  color: #666;
}